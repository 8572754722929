<script>
    export let width = "100%";
    export let height = "100%";
    export let margin = "0.2rem 0";
</script>

<div
    class="skeleton"
    style="margin: {margin}; width: {width}; height: {height};"
></div>

<style>
    .skeleton {
        width: 100%;
        height: 100%;
        border-radius: 4px;
        animation: skeleton-loading 1.2s infinite ease-in-out;
    }

    @keyframes skeleton-loading {
        0%,
        100% {
            background-color: rgb(255, 255, 255); /* Más oscuro */
        }
        50% {
            background-color: rgb(179, 179, 179); /* Intermedio */
        }
    }
</style>
